import React, { useState } from 'react'
import {
    ImageBackground as RNIB,
    ImageBackgroundProps,
    View,
    StyleSheet,
} from 'react-native'

export function ImageBackground(props: ImageBackgroundProps) {
    const { source, style, children, ...rest } = props

    const [svgDimension, setSvgDimension] = useState({})

    const find_dimesions = (layout) => {
        const { x, y, width, height } = layout

        if (width > height) {
            setSvgDimension({ width: '100%' })
            return
        }

        setSvgDimension({ height: '100%' })
    }

    // Transformed .svg's are imported as functions
    if (typeof source === 'function') {
        const SVG = source as React.FC<any>

        return (
            <View
                onLayout={(event) => {
                    find_dimesions(event.nativeEvent.layout)
                }}
                style={styles.svgImageWrapper}
            >
                <SVG style={[styles.svgImage]} {...svgDimension} />
                {children}
            </View>
        )
    }

    return (
        <RNIB
            source={props.source}
            style={props.style}
            resizeMode={props.resizeMode}
            children={props.children}
        />
    )
}

const styles = StyleSheet.create({
    svgImageWrapper: {
        position: 'relative',
    },
    svgImage: {
        position: 'absolute',
    },
})

export { ImageBackgroundProps }

import React, { FC } from 'react'
import { ImageBackground, Text, View, StyleSheet } from 'react-native'
import LinearGradientComponent from '../LinearGradient'
import { BigCardProps } from '../Card/interface'
import Button from '../Button/Button'

export interface BigProductCardProps extends BigCardProps {}

export const BigProductCard: FC<BigProductCardProps> = ({
    containerStyle,
    buttonProps,
    gradientColors = [],
    title,
    description,
    buttonText,
    backgroundImageUrl,
    backgroundImage,
    titleTextStyle,
    descriptionTextStyle,
}) => {
    return (
        <View style={[styles.containerStyle, containerStyle]}>
            {/* @ts-ignore */}
            <ImageBackground
                resizeMode="cover"
                source={backgroundImage || { uri: backgroundImageUrl }}
                style={[styles.imageBackgroundStyle]}
            >
                <LinearGradientComponent
                    colors={gradientColors}
                    style={{
                        ...styles.gradientOverlay,
                    }}
                >
                    <View style={[styles.contentContainerStyle]}>
                        <Text style={[styles.titleStyle, titleTextStyle]}>
                            {title}
                        </Text>
                        <Text
                            style={[
                                styles.descriptionStyle,
                                descriptionTextStyle,
                            ]}
                        >
                            {description}
                        </Text>
                        {buttonText && (
                            <Button
                                {...buttonProps}
                                style={[
                                    styles.buttonStyle,
                                    buttonProps?.style || [],
                                ]}
                                text={buttonText}
                            />
                        )}
                    </View>
                </LinearGradientComponent>
            </ImageBackground>
        </View>
    )
}

const styles = StyleSheet.create({
    containerStyle: {
        borderRadius: 8,
        overflow: 'hidden',
    },
    imageBackgroundStyle: {
        flex: 1,
        height: '100%',
    },
    descriptionStyle: {
        fontSize: 30,
        color: 'white',
        fontWeight: 'bold',
        paddingTop: 4,
        flexShrink: 1,
    },
    titleStyle: {
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold',
    },
    contentContainerStyle: {
        justifyContent: 'flex-end',
        flex: 1,
        padding: 16,
    },
    buttonStyle: {
        backgroundColor: 'white',
        alignSelf: 'flex-start',
        marginTop: 24,
        paddingVertical: 10,
        paddingHorizontal: 16,
        borderRadius: 8,
    },
    gradientOverlay: {
        height: '100%',
    },
})

import { StyleSheet } from 'react-native'
import COLORS from '../../../utils/colors'

const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
        flexShrink: 1,
        backgroundColor: '#fff',
        padding: 16,
    },
    container: {
        width: '100%',
        flexShrink: 1,
    },
    contentStyle: {
        paddingHorizontal: 5,
    },
    navigationHeader: {
        width: '100%',
        flexShrink: 1,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 0,
        marginTop: 6,
    },
    headerTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#374151',
    },
    navButton: {
        padding: 8,
    },
    navButtonText: {
        fontSize: 24,
        color: '#6366f1',
    },
    dateInputContainer: {
        marginBottom: 16,
    },
    dateInput: {
        flex: 1,
        padding: 12,
        fontSize: 16,
        color: '#374151',
    },
    calendarIcon: {
        padding: 12,
        borderLeftWidth: 1,
        borderLeftColor: '#E5E7EB',
    },
    calendar: {
        borderColor: '#E5E7EB',
        borderRadius: 8,
    },
    monthGridContainer: {
        flex: 1,
    },
    monthGrid: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: 8,
    },
    monthButton: {
        width: '30%',
        padding: 12,
        marginBottom: 12,
        borderRadius: 3,
        borderColor: COLORS.COLOR_LIGHTGRAY,
        borderWidth: 1,
        alignItems: 'center',
    },
    selectedMonth: {
        backgroundColor: COLORS.COLOR_SEGMNTD,
    },
    monthText: {
        color: COLORS.COLOR_LISTTEXT,
        fontSize: 14,
    },
    selectedMonthText: {
        color: COLORS.COLOR_HIGHLIGHTBLUE,
    },
    renderArrowStyle: {},
})

export default styles

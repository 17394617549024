import React, { useCallback } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import COLORS from '../../../utils/colors'
import { Calendar as RNCalendar } from 'react-native-calendars'
import moment from 'moment'
import styles from './styles'
import { getMonthNames } from '../utils'
import { MonthlyViewProps } from './interface'

const months = getMonthNames()

export const MonthlyView: React.FC<MonthlyViewProps> = ({
    dateInput,
    renderArrow,
    setView,
    view,
    selectedDate,
    setSelectedDate,
    handleOnDayPress,
    markedDates,
    theme,
    ...props
}) => {
    const handleNavigateMonth = useCallback(
        (direction) => {
            const newDate =
                direction === 'next'
                    ? moment(selectedDate).add(1, 'months')
                    : moment(selectedDate).subtract(1, 'months')
            setSelectedDate(newDate)
        },
        [selectedDate],
    )

    return (
        <View style={styles.container}>
            {dateInput}
            <RNCalendar
                renderArrow={renderArrow}
                customHeaderTitle={
                    <TouchableOpacity
                        onPress={() =>
                            setView(view === 'monthly' ? 'yearly' : 'monthly')
                        }
                    >
                        <Text style={styles.headerTitle}>
                            {selectedDate.format('MMMM YYYY')}
                        </Text>
                    </TouchableOpacity>
                }
                onPressArrowLeft={() => handleNavigateMonth('prev')}
                onPressArrowRight={() => handleNavigateMonth('next')}
                current={selectedDate.format('YYYY-MM-DD')}
                onDayPress={handleOnDayPress}
                markedDates={markedDates}
                style={styles.calendar}
                theme={{
                    contentStyle: styles.contentStyle,
                    // @ts-ignore
                    'stylesheet.calendar.header': {
                        header: styles.navigationHeader,
                    },
                    arrowStyle: { padding: 0 },
                    calendarBackground: 'transparent',
                    selectedDayBackgroundColor: COLORS.COLOR_BLUE,
                    textDayFontWeight: '400',
                    textDayFontSize: 14,
                    textSectionTitleColor: COLORS.COLOR_GRAY,
                    todayTextColor: COLORS.COLOR_BLACK,
                    dayTextColor: COLORS.COLOR_LIGHTBLACK,
                    textDisabledColor: COLORS.COLOR_GRAY_PLACEHOLDER,
                    monthTextColor: COLORS.COLOR_LIGHTBLACK,
                    textMonthFontWeight: '700',
                    textMonthFontSize: 14,
                    selectedDayTextColor: COLORS.COLOR_HIGHLIGHTBLUE,
                    ...theme,
                }}
                {...props}
            />
        </View>
    )
}
